

















































































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

import moment from 'moment'

import '$ui/icons/computer-fill'
import '$ui/icons/database-fill'
import '$ui/icons/backstage-data-fill'
import '$ui/icons/gantt'
import '$ui/icons/cloud-disk-fill'

// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvIcon from '$ui/dv/packages/my-dv-icon'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'

// @ts-ignore
import MyDvGeo from '$ui/dv/packages/my-dv-geo'

// @ts-ignore
import MyBreadcrumb from '$ui/components/my-breadcrumb'

// @ts-ignore
import MyDvLine from '$ui/dv/packages/my-dv-line'
// @ts-ignore
import MyDvProgress from '$ui/dv/packages/my-dv-progress'

// @ts-ignore
import MyDvRing from '$ui/dv/packages/my-dv-ring'
// @ts-ignore
import MyDvBar from '$ui/dv/packages/my-dv-bar'

// @ts-ignore
import MyDvList from '$ui/dv/packages/my-dv-list'

// @ts-ignore
import dateFormat from '$ui/utils/date'

// @ts-ignore
import geoArray from '$ui/charts/geo/index.json'

import MyList from '@/mixins/list'

import Map from './map.vue'

import OrderModal from './list/order.vue'
import CardModal from './list/card.vue'

@Component({
  components: {
    MyDvTitle,
    MyDvMenu,
    MyDvBox,
    MyDvIcon,
    MyDvNumber,
    MyDvText,
    MyDvBorder6,
    MyDvGeo,
    MyBreadcrumb,
    MyDvLine,
    MyDvProgress,
    MyDvRing,
    MyDvBar,
    MyDvList,
    Map,
    OrderModal,
    CardModal,
  },
})
export default class extends Mixins(MyList) {
  orderModalVisible: boolean = false
  orderModalTitle: string = ''
  orderModalParams: any = {}

  cardModalVisible: boolean = false
  cardModalTitle: string = ''
  cardModalParams: any = {}

  menus = [
    { text: '场馆', to: '/v3/' },
    { text: '用户', to: '/v3/user' },
    { text: '运营' },
  ]
  defaultMenuActive = 0

  order1Count = 0
  order2Count = 0
  order3Count = 0

  stats = [
    {
      label: '全部订场订单',
      value: 0,
      icon: 'icon-computer-fill',
      suffix: '单',
      key: 'bill_count',
    },
    {
      label: '全部新增营业时间订单',
      value: 0,
      icon: 'icon-database-fill',
      suffix: '单',
      key: 'yuyue_bill_count',
    },
    {
      label: '全部会员卡销售',
      value: 0,
      icon: 'icon-backstage-data-fill',
      suffix: '单',
      key: 'vip_card_count',
    },
    {
      label: 'AI会员卡销售',
      value: 0,
      icon: 'icon-database-fill',
      suffix: '单',
      key: 'ai_vip_card_count',
    },
  ]

  breadcrumb = [{ label: '全国', geo: 'china.json' }]

  mapName = 'china.json'

  type = {
    人口数量: 'effectScatter',
  }

  chart1 = [
    [
      {
        label: '订场订单数量',
        value: 0,
        suffix: '单',
        key: 'today_order_count',
      },
      {
        label: '订场订单金额',
        value: 0,
        precision: 2,
        suffix: '元',
        key: 'today_order_money',
      },
    ],
    [
      {
        label: '会员卡销售数量',
        value: 0,
        suffix: '单',
        key: 'today_card_count',
      },
      {
        label: '会员卡销售金额',
        value: 0,
        precision: 2,
        suffix: '元',
        key: 'today_card_money',
      },
    ],
  ]

  pie = {
    columns: ['类型', '数量'],
    rows: [['普通包场订单', 0]],
  }

  lineExtend = {
    xAxis: {
      axisLabel: {
        // interval: 'auto',
      },
    },
    series: {
      showSymbol: false,
    },
  }

  barExtend = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    xAxis: {
      boundaryGap: false,
    },
  }

  list1: any = {
    columns: ['场馆', '下单人', '下单时间', '订场信息'],
    rows: [],
  }

  list2: any = {
    columns: ['场馆', '下单人', '下单时间', '订场信息'],
    rows: [],
  }

  list3: any = {
    columns: ['场馆', '下单人', '下单时间', '订场信息'],
    rows: [],
  }

  showOrderModal(title: string, key: string) {
    if (key == 'vip_card_count') {
      this.cardModalTitle = title
      this.cardModalVisible = true
      this.cardModalParams = {}
    } else if (key == 'ai_vip_card_count') {
      this.cardModalTitle = title
      this.cardModalVisible = true
      this.cardModalParams = {
        card_cate: 1,
      }
    } else if (key == 'yuyue_bill_count') {
      this.orderModalTitle = title
      this.orderModalVisible = true
      this.orderModalParams = {
        deal_by: 1,
        bill_cate: 2,
        include_yuyue_time: 1,
      }
    } else if (key == 'bill_count') {
      this.orderModalTitle = title
      this.orderModalVisible = true
      this.orderModalParams = {}
    } else if (['today_card_count', 'today_card_money'].includes(key)) {
      this.cardModalTitle = '今日会员卡销售'
      this.cardModalVisible = true
      this.cardModalParams = {
        created_time1: moment().format('YYYY-MM-DD') + ' 00:00:00',
      }
    } else if (['today_order_count', 'today_order_money'].includes(key)) {
      this.orderModalTitle = '今日订场订单'
      this.orderModalVisible = true
      this.orderModalParams = {
        created_time1: moment().format('YYYY-MM-DD') + ' 00:00:00',
      }
    }
  }

  hideOrderModal() {
    this.orderModalVisible = false
  }

  showCardModal(title: string, key: string) {
    this.cardModalTitle = title

    this.cardModalVisible = true
  }

  hideCardModal() {
    this.cardModalVisible = false
  }

  mounted() {
    this.orderSummary()
    this.billTypeLoader()

    this.todayBuy()

    this.todayPlay()

    this.todayBillList('')
    this.todayBillList('ongoing')
    this.todayBillList('ended')
  }

  async todayBillList(type: string) {
    const res = await this.$store.dispatch('datav/todayBillList', {
      type,
    })
    if (res && res.Data) {
      let rows: any[] = []
      for (const obj of res.Data) {
        rows.push([
          obj.stadium_name,
          obj.username + '\n' + this.renderMobile(obj.user_mobile),
          obj.created_time,
          this.renderOrderInfo(obj),
        ])
      }
      if (type == 'ongoing') {
        this.list2.rows = rows
      } else if (type == 'ended') {
        this.list3.rows = rows
      } else {
        this.list1.rows = rows
      }
    }
  }

  async todayBuy() {
    const res = await this.$store.dispatch('datav/todayBuy', {})

    if (res && res.Data) {
      this.chart1[0][0].value = res.Data.order_count
      this.chart1[0][1].value = res.Data.order_money

      this.chart1[1][0].value = res.Data.card_count
      this.chart1[1][1].value = res.Data.card_money
    }
  }

  async todayPlay() {
    const res = await this.$store.dispatch('datav/todayPlay', {})

    if (res && res.Data) {
      this.order1Count = res.Data.order1
      this.order2Count = res.Data.order2
      this.order3Count = res.Data.order3
    }
  }

  async orderSummary() {
    const res = await this.$store.dispatch('datav/orderSummary', {})

    if (res && res.Data) {
      this.stats[0].value = res.Data.bill_count
      this.stats[1].value = res.Data.yuyue_bill_count
      this.stats[2].value = res.Data.vip_card_count
      this.stats[3].value = res.Data.ai_vip_card_count
    }
  }

  async billTypeLoader() {
    const res = await this.$store.dispatch('datav/billType', {
      from: 'admin',
    })
    // console.log(res)
    let rows = []
    if (res && res.Data) {
      for (const obj of res.Data) {
        rows.push([obj.name, obj.value])
      }
    }

    this.pie = {
      columns: ['类型', '数量'],
      rows,
    }
  }

  async daysLoader() {
    const res = await this.$store.dispatch('statistics/getOrders', {})
    // console.log(res)
    let rows = []
    if (res && res.Data) {
      for (const obj of res.Data.categories) {
        rows.push([obj, res.Data.data[obj]])
      }
    }

    return {
      columns: ['日期', '数量'],
      rows,
    }
  }

  async hourLoader() {
    const res = await this.$store.dispatch('statistics/getHourTrend', {})
    // console.log(res)
    let rows = []
    if (res && res.Data) {
      for (const k in res.Data.categories) {
        rows.push([res.Data.categories[k], res.Data.data[k], res.Data.data1[k]])
      }
    }

    return {
      columns: ['时段', '订场', '打球'],
      rows,
    }
  }

  json({ map }: any) {
    // @ts-ignore
    this.$refs.geo.loading = true
    const [path, file] = map.split('/')

    if (!file) {
      return import('$ui/charts/geo/china' + '.json')
    }

    return path === 'province'
      ? import('$ui/charts/geo/province/' + file)
      : import('$ui/charts/geo/city/' + file)
  }

  get activeIndex() {
    return this.breadcrumb.length - 1
  }

  handleClick(params: any) {
    const name = params.name.replace('市', '')
    const geoItem = geoArray.find((item: any) => item.name === name)
    if (geoItem) {
      this.mapName = geoItem.geo
      this.breadcrumb.push({
        label: params.name,
        ...geoItem,
      })
    }
  }

  handleMapRegister() {
    // @ts-ignore
    this.$refs.geo.loading = false
  }

  handleMenuClick(item: any, index: number) {
    this.breadcrumb.splice(index + 1)
    this.mapName = item.geo
  }

  loader({ name }: any) {
    return Promise.resolve({
      columns: ['省份', '人口数量'],
      rows: [
        ['广州', 225344],
        ['广州市', 1323],
      ],
    })
  }
}
