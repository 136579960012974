var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MyDialog',{attrs:{"title":_vm.title,"handleCancel":_vm.handleCancel,"pageVisible":true}},[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"用户"}},[_c('a-input')],1),_c('a-form-item',{attrs:{"label":"场馆"}},[_c('a-input')],1),_c('a-form-item',{attrs:{"label":"时间"}},[_c('a-input')],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("查询")])],1)],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-table',{attrs:{"size":"small","loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.data,"rowKey":function (record) { return record.id; },"pagination":_vm.pagination},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"avatar",fn:function(record){return [_c('a-avatar',{attrs:{"src":_vm.renderImage(record.avatar)}})]}},{key:"cost",fn:function(record){return [_vm._v(" "+_vm._s(_vm.renderMoney(record.cost / 100))+" ")]}},{key:"is_pay",fn:function(record){return [(record.paid_time != null)?_c('a-badge',{attrs:{"status":"success","text":"已支付"}}):_c('a-badge',{attrs:{"status":"error","text":"未支付"}})]}},{key:"orderinfo",fn:function(record){return [_c('div',{staticStyle:{"width":"300px"}},[_vm._v(" "+_vm._s(_vm.renderOrderInfo(record))+" ")])]}},{key:"bill_status",fn:function(record){return [(
              record.bill_status == 1 &&
              _vm.renderTime(record.valid_time_start) >= _vm.curtime
            )?[_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("未开始")])]:(
              record.bill_status == 1 &&
              _vm.renderTime(record.valid_time_start) <= _vm.curtime &&
              _vm.renderTime(record.valid_time_end) > _vm.curtime
            )?[_c('a-tag',{attrs:{"color":"blue"}},[_vm._v("进行中")])]:(
              record.bill_status == 1 &&
              _vm.curtime > _vm.renderTime(record.valid_time_end)
            )?[_c('a-tag',{attrs:{"color":"green"}},[_vm._v("已结束")])]:(record.bill_status == 3)?[_c('a-tag',{attrs:{"color":"red"}},[_vm._v("已取消")])]:_vm._e(),(record.wechat_bill_status)?[_c('a-tooltip',{attrs:{"title":"微信订场订单的状态"}},[(record.wechat_bill_status == '1')?_c('a-tag',[_vm._v("已接单")]):(record.wechat_bill_status == '2')?_c('a-tag',[_vm._v("已拒绝")]):(record.wechat_bill_status == '3')?_c('a-tag',[_vm._v("自动取消")]):(record.wechat_bill_status == '4')?_c('a-tag',[_vm._v("球友取消")]):(record.wechat_bill_status == '5')?_c('a-tag',[_vm._v("已拒绝(代)")]):(record.wechat_bill_status == '6')?_c('a-tag',[_vm._v("已接单(代)")]):(record.wechat_bill_status == '7')?_c('a-tag',[_vm._v("强制取消")]):(record.wechat_bill_status == '8')?_c('a-tag',[_vm._v("自动接单")]):_c('a-tag',[_vm._v("待定")])],1)]:_vm._e()]}},{key:"username",fn:function(record){return [_vm._v(" "+_vm._s(record.username)+" "),_c('br'),_vm._v(_vm._s(_vm.renderMobile(record.mobile))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }