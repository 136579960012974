


















































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'
import MyList from '@/mixins/list'

import MyDialog from '@/components/dialog.vue'

@Component({
  components: {
    MyDialog,
  },
})
export default class extends Mixins(MyList) {
  @Prop() title!: string

  @Prop() params?: any

  @Prop() stadiumId!: number

  dispatchType: string = 'bill/getBills'

  curtime: number = moment().unix()

  columns: any[] = [
    { title: '头像', scopedSlots: { customRender: 'avatar' } },
    { title: '用户名', scopedSlots: { customRender: 'username' } },
    { title: '场馆', dataIndex: 'stadium_name' },
    { title: '订场信息', scopedSlots: { customRender: 'orderinfo' } },
    { title: '金额', scopedSlots: { customRender: 'cost' } },
    { title: '下单时间', dataIndex: 'created_time' },
    { title: '支付方式', dataIndex: 'channel_name' },
    { title: '支付状态', scopedSlots: { customRender: 'is_pay' } },
    { title: '订单状态', scopedSlots: { customRender: 'bill_status' } },
  ]

  mounted() {
    this.loadData(
      {
        ...this.params,
      },
      false
    )
  }
}
