










































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyList from '@/mixins/list'

import MyDialog from '@/components/dialog.vue'

@Component({
  components: {
    MyDialog,
  },
})
export default class extends Mixins(MyList) {
  @Prop() title!: string

  @Prop() params?: any

  dispatchType: string = 'users/vipcard'

  columns: any[] = [
    { title: '头像', scopedSlots: { customRender: 'avatar' } },
    { title: '用户名', scopedSlots: { customRender: 'username' } },
    { title: '场馆', dataIndex: 'stadium_name' },
    { title: '卡名称', scopedSlots: { customRender: 'card_name' } },
    { title: '卡余额', scopedSlots: { customRender: 'balance' } },
    { title: '购买时间', dataIndex: 'recharge_time' },
    { title: '过期时间', dataIndex: 'valid_time_end' },
    { title: '过期状态', scopedSlots: { customRender: 'status' } },
    { title: '删除状态', scopedSlots: { customRender: 'is_deleted' } },
    { title: '购买方式', scopedSlots: { customRender: 'from_user_id' } },
  ]

  mounted() {
    this.loadData(
      {
        ...this.params,
      },
      false
    )
  }
}
